import logo from '../logo.svg';
import '../assets/Home.css';
import React, { useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

function Home() {
  const [formData, setFormData] = useState({
    site_stk: "PRO01",
    loc_itm: "",
    ref_itm: "",
    lot_itm: "",
    qte_itm: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = () => {

    console.log('Form data:', formData);
  };

  return (

    <div className="App">
      
      <form id="form">
      <label htmlFor="site_stk">Site :</label>
      <select
        id="site_stk"
        name="site_stk"
        value={formData.site_stk}
        onChange={handleChange}
      >
        <option value="PRO01">PRO01</option>
        <option value="PRO02">PRO02</option>
        <option value="PRI01">PRI01</option>
        <option value="PAR01">PAR01</option>
      </select>

      <label htmlFor="loc_itm">Emplacement :</label>
      <input
        type="text"
        id="loc_itm"
        name="loc_itm"
        value={formData.loc_itm}
        onChange={handleChange}
        required
      />

      <label htmlFor="ref_itm">Référence article :</label>
      <label id="des_itm"></label>
      <input
        type="text"
        id="ref_itm"
        name="ref_itm"
        value={formData.ref_itm}
        onChange={handleChange}
        required
      />

      <label htmlFor="lot_itm">Lot article :</label>
      <input
        type="text"
        id="lot_itm"
        name="lot_itm"
        value={formData.lot_itm}
        onChange={handleChange}
      />

      <label htmlFor="qte_itm">Qte :</label>
      <input
        type="number"
        id="qte_itm"
        name="qte_itm"
        value={formData.qte_itm}
        onChange={handleChange}
        step="any"
        required
      />

      <input
        type="button"
        id="post"
        value="Valider le comptage"
        onClick={handleSubmit}
      />
    </form>
    </div>
  );
}

export default Home;